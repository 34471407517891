import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicErrorKeys, Claim, ErrorCache } from '@domgen/dgx-components';
import * as fromClaims from '../+state';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { differenceInSeconds } from 'date-fns';
import {
  ErrorPageContent,
  ErrorTypes,
  ContactDetails,
  ErrorMessages,
} from '@domgen/dgx-components';
import { ClaimHelperService } from './claim-helper.service';
import { Api } from '@domgen/dgx-components';
import { ProductDetails } from '@domgen/dgx-components';

enum ClaimTypeNames {
  Breakdown = 'Breakdown',
  Accident = 'Damage Caused by Accident',
  BreakdownBoiler = 'Breakdown (Boiler & Controls)',
  AnnualService = 'Annual Service',
}

class RejectionReasons {
  public static readonly GasLeak = /gas.*emergency.*service/;
  public static readonly BoilerWorkingOrder = /boiler.*working.*order/;
}

enum RejectionMessages {
  Default = `Unfortunately, we are unable to complete your request online. If you’d like to know more about why we couldn’t complete your booking, you can contact us or visit our FAQ page.`,
  NoEngineer = `Unfortunately, we are unable to find an available engineer. But don’t worry – you can continue with your repair booking over the phone.`,
}

interface ErrPageContent {
  title: string;
  description: string;
  ctaText: string;
  errorType: ErrorTypes;
  shortErrorDescription: string;
  descriptionLine2?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private readonly defaultErrorDescription = `Unfortunately, we are unable to complete your request online. Don’t worry - you can complete your repair booking over the phone by calling the number below.`;
  private errorCache: ErrorCache;
  private legacyDefaults = true;
  constructor(
    private store: Store<fromClaims.State>,
    private router: Router,
    private claimHelper: ClaimHelperService
  ) {}

  cacheError(err: BasicErrorKeys) {
    if (!err) {
      this.errorCache = undefined;
      return;
    }
    this.errorCache = { values: err, timeStamp: Date.now() };
  }

  errorPageContent(
    legacyDefaults: boolean = true
  ): Observable<ErrorPageContent> {
    this.legacyDefaults = legacyDefaults;
    return this.getClaim().pipe(
      take(1),
      map(({ claim, active }) => ({
        claim,
        active,
        ...this.errorMessages(claim),
        contactDetails: this.getContactDetails(claim),
        product: claim?.reflect ? this.getProductDetails(claim) : null,
        errorCache: this.errorCache ? this.errorCache.values : undefined,
      }))
    );
  }

  private getProductDetails(claim: Claim): ProductDetails {
    return {
      title: this.getErrorTitleHeading(claim),
      brand: claim?.reflect?.manufacturer,
      applianceName: claim?.reflect?.productType,
      planNumber: claim?.reflect?.planNumber,
      modelNumber: this.claimHelper.getModelNumberHeading(claim) as string,
    };
  }

  private getErrorTitleHeading(claim: Claim): string {
    const hasASVType = claim?.getData?.ClaimTypes.find(
      (c: Api.ClaimType) => c.ClaimTypeCode === 'ASV'
    );
    const isASV =
      claim?.reflect?.asvOffered === 'true' || hasASVType
        ? hasASVType?.ClaimTypeID ===
          claim?.claimSelection?.request?.ClaimTypeID
        : false;
    return isASV ? 'Annual Service' : 'Repair details';
  }

  private OEMBrandsDataCheck(claim: Claim): boolean {
    return (
      !claim?.getData?.BookingOEM &&
      ['whirlpool mb', 'whirlpool'].includes(
        claim?.reflect?.manufacturer?.toLowerCase() as string
      )
    );
  }

  private errorMessages(claim: Claim): ErrPageContent {
    if (
      this.isTechnicalError() &&
      (!claim?.reflect || this.OEMBrandsDataCheck(claim))
    ) {
      // 500+ error messages - show Technical Error page
      return this.technicalErrorMessages();
    }

    const getContactDetails = this.getContactDetails(claim);
    const defaultNumber = getContactDetails.SpPhoneNumber === '0333 000 9703';

    const currentPage = this.router.url.replace(/\//g, '');
    // start with default messages
    const msgs: ErrPageContent = {
      title: this.legacyDefaults
        ? 'We couldn’t book your repair'
        : `Something went wrong`,
      description: this.legacyDefaults
        ? this.defaultErrorDescription
        : `Sorry, we’ve run into a booking error. Please give ${
            defaultNumber ? 'us' : claim.reflect?.manufacturer
          } a call to arrange your repair.`,
      ctaText: 'Back to plan details',
      errorType: currentPage === 'error' ? ErrorTypes.Hard : ErrorTypes.Soft,
      shortErrorDescription: 'Generic Error',
    };

    const claimTypeID = claim?.claimSelection?.request?.ClaimTypeID;
    const claimTypeName = claimTypeID
      ? claim.getData?.ClaimTypes?.find((t) => t.ClaimTypeID === claimTypeID)
          ?.ClaimTypeName
      : null;

    // default Error short description
    if (
      this.errorCacheValid() &&
      claim?.rejectedMessage !== msgs.shortErrorDescription
    ) {
      msgs.shortErrorDescription = this.errorCache?.values?.message || '';
    }

    if (msgs.errorType === ErrorTypes.Soft) {
      // Reject messages
      msgs.title = 'We couldn’t complete your booking';
      msgs.shortErrorDescription = 'Generic Reject';
      msgs.description = claim?.rejectedMessage || RejectionMessages.Default;

      switch (claimTypeName) {
        case ClaimTypeNames.BreakdownBoiler:
          if (claim?.rejectedMessage?.match(RejectionReasons.GasLeak)) {
            msgs.shortErrorDescription = 'Gas Leak';
          }
          break;
        case ClaimTypeNames.AnnualService:
          if (
            claim?.rejectedMessage?.match(RejectionReasons.BoilerWorkingOrder)
          ) {
            msgs.shortErrorDescription = 'Boiler Working Order';
          }
          break;
      }
    } else {
      // Error messages

      if (
        this.errorCacheValid()?.message === ErrorMessages.NoEngineerAvailable
      ) {
        // NoEngineerAvailable error treated as a Reject
        msgs.title = 'We couldn’t find an engineer in your area';
        msgs.description = RejectionMessages.NoEngineer;
      }

      if (this.errorCacheValid()?.message === ErrorMessages.NotEligibleForASV) {
        msgs.title = 'Your next service isn’t due yet';
        msgs.description =
          'It looks like your boiler’s already had its service for this year.';
        msgs.descriptionLine2 =
          'Your plan includes one service every 12 months.';
        msgs.ctaText = 'Back to manage my repair';
        msgs.shortErrorDescription = 'NC003 error (ASV not eligible)';
      }
    }
    return msgs;
  }

  private errorCacheValid(): BasicErrorKeys | undefined {
    return this.errorCache &&
      differenceInSeconds(Date.now(), this.errorCache?.timeStamp) < 3
      ? this.errorCache.values
      : undefined;
  }

  private isTechnicalError(): boolean {
    return (
      !!this.errorCache?.values?.message?.match(/^5[0-9]{2}\s\w+/) ||
      !!this.errorCache?.values?.status?.toString().match(/^5[0-9]{2}/) ||
      !!this.errorCache?.values?.message?.match(/^0{1}\s\w+/) ||
      !!this.errorCache?.values?.status?.toString().match(/^0{1}/)
    );
  }

  private isASVError(): boolean {
    return (
      this.errorCache?.values?.errorCode === 'NC003' ||
      this.errorCache?.values?.message === '503 Orbit code: NC003'
    );
  }

  private technicalErrorMessages(): ErrPageContent {
    return {
      title: 'Something went wrong',
      description: `Sorry, we’ve run into a booking error. Please give us a call to arrange your repair.`,
      ctaText: '',
      errorType: ErrorTypes.Technical,
      shortErrorDescription: this.errorCache?.values?.message || '',
    };
  }

  private getClaim(): Observable<{ claim: Claim; active: boolean }> {
    return this.store.select(fromClaims.getActiveClaim).pipe(
      map((claim) => {
        return {
          claim,
          active: !!claim?.getData || !!claim?.reflect,
        };
      })
    ) as Observable<{ claim: Claim; active: boolean }>;
  }

  private getBookingOEM(claim: Claim): string {
    if (claim?.getData?.BookingOEM) {
      return claim.getData.BookingOEM;
    }

    // dirive BookingOEM
    if (claim?.reflect?.manufacturer) {
      if (
        [
          'CANDY',
          'HOOVER',
          'BAUMATIC',
          'ROSIERES',
          'IBERNA',
          'KELVINATOR',
          'HAIER',
        ].includes(claim.reflect.manufacturer)
      ) {
        return 'Hoover';
      }

      if (
        [
          'HEATRAE',
          'POTTERTON',
          'MYSON THORN',
          'BAXI',
          'REMEHA',
          'MAIN',
          'MEGAFLO',
          'SANTON',
        ].includes(claim.reflect.manufacturer)
      ) {
        return 'Baxi';
      }
    }

    return '';
  }

  private getContactDetails(claim: Claim): ContactDetails {
    if (
      (this.isTechnicalError() && !claim?.reflect) ||
      this.OEMBrandsDataCheck(claim)
    ) {
      return this.allContactDetails().TECHNICAL;
    }

    if (this.isASVError()) {
      const bookingOEM = this.getBookingOEM(claim);

      return {
        ...this.allContactDetails()[bookingOEM],
        SpCallText: `If you have any questions, just give us a call on:`,
        SpOpeningHours:
          '*Calls cost the basic rate per minute plus your phone company’s access charge. From a mobile, they can cost much more.',
      };
    }

    return (
      this.allContactDetails()[this.getBookingOEM(claim)] ||
      this.allContactDetails().DEFAULT
    );
  }

  private allContactDetails(): { [key: string]: ContactDetails } {
    const standardRate = `Calls cost the standard UK landline rate.`;
    const defaultCharge = `Calls cost the standard UK landline rate. Calls to 0333 numbers will cost you no more than 01 or 02 numbers from landlines and mobiles. If you get 'inclusive minutes' with your package, calls to 0333 numbers will be part of these.`;
    const defaultNumber = `0333 000 9703`;
    const defaultGetInTouchText = `Need to get in touch?`;
    const defaultCallText = `Give us a call on`;
    return {
      Baxi: {
        BookingOEM: 'Baxi',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: '0344 335 6556',
        SpOpeningHours: standardRate,
        SpCallCosts:
          'Lines are open 8am-8pm, Monday-Friday and 8.30am-2pm, Saturday-Sunday.',
      },
      Hoover: {
        BookingOEM: 'Hoover',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: '0344 499 5599',
        SpOpeningHours: standardRate,
        SpCallCosts:
          'Lines open Mon-Fri 8am-5:30pm, Sat 9am-5pm, Sun 10am-4pm.',
      },
      'Hoover Candy': {
        BookingOEM: 'Hoover Candy',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: '0344 499 5599',
        SpOpeningHours: standardRate,
        SpCallCosts:
          'Lines open Mon-Fri 8am-5:30pm, Sat 9am-5pm, Sun 10am-4pm.',
      },
      Whirlpool: {
        BookingOEM: 'Whirlpool',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: '0344 822 7227',
        SpOpeningHours: standardRate,
        SpCallCosts:
          'Lines open Mon-Fri 8am-6pm, Sat 8:30am-4:30pm, Sun 9:30am-3:30pm.',
      },
      'Whirlpool MB': {
        BookingOEM: 'Whirlpool MB',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: defaultNumber,
        SpCallCosts: defaultCharge,
      },
      Electrolux: {
        BookingOEM: 'Electrolux',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: defaultNumber,
        SpCallCosts: defaultCharge,
      },
      Beko: {
        BookingOEM: 'Beko',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: defaultNumber,
        SpCallCosts: defaultCharge,
      },
      TECHNICAL: {
        BookingOEM: 'TECHNICAL',
        GetInTouchText: null,
        SpCallText: 'Just give us a call on',
        SpPhoneNumber: defaultNumber,
        SpCallCosts: defaultCharge,
      },
      DEFAULT: {
        BookingOEM: 'DEFAULT',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: defaultNumber,
        SpCallCosts: defaultCharge,
      },
    };
  }
}
